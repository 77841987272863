import '~/assets/styles/index.scss';

import React from 'react';
import { GeoLocationProvider } from '~/app/context';

export const onInitialClientRender = () => {
  if (window.location.pathname !== window.location.pathname.toLowerCase()) {
    window.location.href = window.location.origin + window.location.pathname.toLowerCase();
  }
};

export const wrapRootElement = ({ element }) => {
  return <GeoLocationProvider>{element}</GeoLocationProvider>;
};
